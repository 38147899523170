/* Reset */

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
}

a {
  color: white;
  text-decoration: none;
}

span {
  font-family: 'UbuntuLight';
}

span.bold {
  font-family: 'UbuntuBold';
}

.disable-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Audio */

audio:focus {
  outline: none;
}

/* Nav */

.is-active {
  border-bottom: 3px solid white;
}

/* Fonts */

@font-face {
  font-family: 'PlayfairDisplay';
  src: local('PlayfairDisplay'), url(./fonts/PlayfairDisplay-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./fonts/Ubuntu-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'UbuntuLight';
  src: local('UbuntuLight'), url(./fonts/Ubuntu-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'UbuntuBold';
  src: local('UbuntuBold'), url(./fonts/Ubuntu-Bold.ttf) format('truetype');
}

/* Animations */

@keyframes pulsating {
  0% {
    transform: scale(0.9, 0.9);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(0.9, 0.9);
  }
}

.pulsating {
  animation: 1s linear 0s infinite pulsating;
}

@keyframes swiping {
  0% {
    transform: translate(0);
  }

  25% {
    transform: translate(-20px);
  }

  50% {
    transform: translate(0px);
  }

  75% {
    transform: translate(20px);
  }

  100% {
    transform: translate(0px);
  }
}

.swiping {
  animation: 1s linear 0s infinite swiping;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoomOut {
  0% {
    background-size: 500% 500%;
  }

  100% {
    background-size: 100% 100%;
  }
}

.rotating-vinyl {
  pointer-events: none;
  will-change: transform;
  animation: 30s linear 0s zoomOut infinite, 3s linear 0s infinite rotating;
}

@keyframes slideup {
  0% {
    transform: translateY(0em);
  }

  100% {
    transform: translateY(50em);
  }
}

.slideup {
  transition: all;
  animation: 3s linear 0s slideup;
}

/* Vinyl */

.vinyl-container {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 10px 0px;
  cursor: pointer;
  z-index: 1;
}

.vinyl-container-mobile {
  width: 200px;
  height: 200px;
}

.vinyl-mute-container {
  position: absolute;
  left: 0px;
  top: 0px;
  pointer-events: none;
}

.vinyl-mute-icon {
  color: #fff;
  background: black;
  border-radius: 100%;
}

.vinyl-container-mobile .vinyl-mute-icon {
  width: 150px !important;
  height: 150px !important;
}

.vinyl-container-desktop .vinyl-mute-icon {
  width: 175px !important;
  height: 175px !important;
}

.vinyl-container .album-art-container {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vinyl-container-mobile .album-art-container {
  width: 200px;
  height: 200px;
}

.album-art {
  width: 190px;
  height: 190px;
  border-radius: 100%;
  margin: 10px;
  background-size: cover;
  transition: all;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ######### General ######### */

/* Links */

a.text-link {
  color: orange;
  font-family: 'UbuntuLight';
  margin: '5px';
  border-bottom: 3px solid transparent;
}

a.text-link:hover {
  box-sizing: border-box;
  border-bottom: 3px solid;
}

.button-link {
  background: orange;
  color: white;
  padding: 15px;
  font-family: 'UbuntuBold';
  border: 3px solid orange;
  margin: 10px;
  transition: 0.2s all;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
}

.button-link:hover {
  transform: translateY(-5px);
}

.button-link.secondary {
  background: transparent;
  color: orange;
}

/* Icons */

.like-icon {
  color: orange;
  cursor: pointer;
}

/* ######### Pages ######### */

/* FAQ */

@media only screen and (min-width: 600px) {
  .accordion {
    margin: 0px 200px;
  }
}

.accordion {
  width: 100%;
  margin-bottom: 10px;
}

.accordion__heading {
  font-family: 'UbuntuBold';
  overflow: 'hidden';
}

.accordion a {
  color: black;
  font-family: 'UbuntuBold';
}

.accordion a:hover {
  border-bottom: 3px solid black;
}

.accordion__panel {
  background: #dedede;
  font-family: 'UbuntuLight';
  letter-spacing: '5px';
  line-height: '10em';
}

/* Game Lobby */

.countdown {
  display: block;
  font-family: 'UbuntuLight';
  font-size: 90px;
  text-align: center;
}

.countdown-emoji {
  display: block;
  font-size: 90px;
  text-align: center;
}

/* Game */

/* Chat */

input.chat-input {
  pointer-events: auto;
  font-size: 1.25em;
  border-top: 1px solid orange;
  border-radius: 0px;
}

.chat-list {
  height: 85%;
}

.chat-message {
  margin-top: 25px;
}

.chat-message-body {
  margin-left: 10px;
}

.chat-input {
  background: black;
  color: white;
  outline: none;
  border: 0px;
  padding: 10px;
  margin-top: 20px;
  height: 10%;
  font-family: 'UbuntuBold';
  width: 100%;
}

.chat-list .chat-follow-button {
  background: orange;
  transition: all 0.2s;
}

.chat-list .chat-follow-button:hover {
  transform: scale(1.5);
  background: orange;
}

/* MenuBar */
.menu-bar {
  position: fixed;
}

.menu-bar-desktop {
  left: 0px;
  height: 100% !important;
  width: auto !important;
}

.menu-bar-mobile {
  bottom: 0px;
  height: 50px !important;
  width: 100% !important;
  font-size: 0.8em;
}

.menu-item {
  color: #dedede;
  background: black;
  font-family: 'UbuntuBold';
  cursor: pointer;
}

.menu-item:hover,
.menu-item-active {
  color: orange;
}

.menu-item-desktop {
  padding: 25px;
}

/* Content Box */

.content-box {
  transition: 0.2s ease-in;
  z-index: 9999;
}

.content-box.desktop {
  width: 400px;
  min-width: 400px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

.content-box.mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  margin: 0px;
  border-radius: 0px;
}

.content-box.hidden {
  transform: translateX(100%);
}

.content-box .close-button {
  cursor: pointer;
}

.content-box .close-button:hover {
  color: orange;
}

/* Artists Selection */
.artist-selection-title {
  margin: 20px 5px !important;
}

.artist-selection-title .bold {
  font-family: 'UbuntuBold';
}

.artist-selection-artist-container {
  width: 200px;
  height: 200px;
  margin: 5px;
  position: relative;
  cursor: pointer;
  opacity: 0.75;
}

.artist-selection-artist-container-desktop {
  width: 200px !important;
  height: 200px !important;
}

.artist-selection-artist-container-mobile {
  width: 100px !important;
  height: 100px !important;
}

.artist-selection-artist-container-desktop:hover {
  opacity: 1;
}

.artist-selection-artist-container.selected {
  opacity: 1;
  border: 5px solid orange;
}

.artist-selection-artist-text {
  font-family: 'Ubuntu';
  /* width: 100px; */
  background: black;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0px;
  z-index: 1;
}

.artist-selection-artist-container.selected .artist-selection-artist-text {
  font-family: 'UbuntuBold';
}

.artist-selection-artist-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* Track Info Text */
.track-info-container {
  margin: 5px;
}

.track-info-container.desktop {
  margin: 20px;
}

/* React Toastify */
.Toastify__toast-container .Toastify__progress-bar--dark {
  background: orange;
}
/* 
.Toastify__toast-container .Toastify__toast--info {
  background: orange;
}

.Toastify__toast-container .Toastify__progress-bar--info {
  background: #ffe600;
} */

/* Answers */

.answers-container.mobile {
  width: 90% !important;
}

/* Faq */

.faq-container {
  margin: 0 auto;
}
